<template>
  <div>
    <header class="page-header">
      <el-row :gutter="20" align="middle">
        <el-col :span="12">
          <h2>Бүтээгдэхүүний жагсаалт</h2>
        </el-col>
      </el-row>
    </header>
    <div class="panel tabled">
      <el-tabs v-model="activeTabStatus" @tab-click="tabClick">
        <el-tab-pane label="All" name="all">
          <div class="panel-content">
            <div class="pl20 pr20 table-filter mb10">
                  <el-row :gutter="10" align="middle" type="flex">
                      <el-col :span="20">
                          <el-input placeholder="Хайх" class="input-with-select" v-model="search" @input="onSearch">
                              <el-button icon="el-icon-search" slot="prepend"></el-button>
                          </el-input>
                      </el-col>
                  </el-row>
              </div>
            <el-table
              v-loading="loading"
              :data="products"
              :span-method="orderSpanMethod"
              style="width: 100%"
              :row-class-name="orderClassName"
              @selection-change="handleSelectionChange">
              <el-table-column label="Дугаар" width="70px">
                <template slot-scope="scope">
                  <strong>{{scope.row.order_number}}</strong>
                </template>
              </el-table-column>
              <el-table-column
                label="Бүтээгдэхүүн"
                width="250">
                <template slot-scope="scope">
                  <el-row :gutter="10" align="middle" type="flex">
                    <el-col :span="6">
                      <el-image
                        class="image-holder"
                        :src="Array.isArray(scope.row.product_image) ? scope.row.product_image[0] : scope.row.product_image"
                        fit="contain"></el-image>
                    </el-col>
                    <el-col :span="18">
                      <strong>{{scope.row.product_name}}</strong>
                      <br />
                      <span class="secondary">{{ scope.row.variant_name }}</span>
                    </el-col>
                  </el-row>
                </template>
              </el-table-column>
              <el-table-column label="SKU" width="160px" prop="sku">
              </el-table-column>
              <el-table-column  e-column label="Агуулах" width="120px">
                <template slot-scope="scope">
                  <!-- <strong>{{scope.row.order_number}}</strong> -->
                  <el-select v-model="scope.row.selectedWarehouse" placeholder="Select">
                    <el-option
                      v-for="item in scope.row.warehouses"
                      :key="item.warehouse_id"
                      :label="item.name_mon"
                      :value="item.warehouse_id">
                    </el-option>
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column label="Тавиур">
                <template slot-scope="scope">
                  <strong>{{generateData('rack', scope.row.warehouses, scope.row.selectedWarehouse)}}</strong>
                </template>
              </el-table-column>
              <el-table-column label="Давхар">
                <template slot-scope="scope">
                  <strong>{{generateData('shelf', scope.row.warehouses, scope.row.selectedWarehouse)}}</strong>
                </template>
              </el-table-column>
              <el-table-column label="Нүд" width="50px">
                <template slot-scope="scope">
                  <strong>{{generateData('unit', scope.row.warehouses, scope.row.selectedWarehouse)}}</strong>
                </template>
              </el-table-column>
              <el-table-column label="Ширхэг">
                <template slot-scope="scope">
                  {{parseInt(scope.row.item_count)}}
                </template>
              </el-table-column>
              <el-table-column label="Бэлдсэн эсэх" width="110px">
                <template slot-scope="scope">
                  <el-button type="success" icon="el-icon-check" disabled circle v-if="scope.row.fulfillment">
                  </el-button>
                  <el-button type="primary" icon="el-icon-folder" @click="setFullFillment(scope.row.id)" circle v-else>
                  </el-button>
                </template>
              </el-table-column>
              <!-- <el-table-column label="Нийлүүлэгч" width="200px" prop="supplier_name">
              </el-table-column>
              <el-table-column label="Брэнд" width="200px" prop="brand_name">
              </el-table-column> -->
            </el-table>
            <el-pagination
              @current-change = "curentPageHandler"
              @size-change = "sizeChangeHandler"
              class="text-right mt10"
              background
              :current-page.sync="currentPage"
              :page-size="pageSize"
              :page-sizes="[5, 20, 50, 100]"
              layout="total, sizes, prev, pager, next"
              :total="totalCount">
            </el-pagination>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
import services from '../../../helpers/services'
import helper from '../../../helpers/helper'
export default {
  name: 'orderCollect',
  data () {
    return {
      products: [],
      option: [
        { index: 0, field: 'order_id' }
      ],
      selectedData: [],
      loading: false,
      activeTabStatus: 'all',
      search: '',
      totalCount: 0,
      currentPage: 1,
      pageSize: 20
    }
  },

  mounted () {
    var from = (this.pageSize * (this.currentPage - 1))
    var size = this.pageSize
    if (this.$route.query.page && this.$route.query.size && this.$route.query.search) {
      const incomingPage = parseFloat(this.$route.query.page)
      const incomingSize = parseFloat(this.$route.query.size)
      const incomingSearch = this.$route.query.search
      this.currentPage = incomingPage
      this.pageSize = incomingSize
      this.search = incomingSearch
      from = (this.pageSize * (incomingPage - 1))
      size = incomingSize
    }
    this.getOrders(from, size, this.search)
  },

  methods: {
    onSearch () {
      this.$router.push({ name: 'orderCollect', query: { page: this.currentPage, size: this.pageSize, search: this.search } })
      this.getOrders(this.pageSize * (this.currentPage - 1), this.pageSize, this.search)
    },

    sizeChangeHandler (item) {
      this.pageSize = item
      this.$router.push({ name: 'orderCollect', query: { page: this.currentPage, size: this.pageSize, search: this.search } })
      this.getOrders((this.pageSize * (this.currentPage - 1)), this.pageSize, this.search)
    },

    curentPageHandler (item) {
      this.currentPage = item
      this.$router.push({ name: 'orderCollect', query: { page: item, size: this.pageSize, search: this.search } })
      this.getOrders((this.pageSize * (this.currentPage - 1)), this.pageSize, this.search)
    },

    orderClassName ({ row, rowIndex }) {
      if (row.fulfillment) {
        return 'success-row'
      } else {
        return 'warning-row'
      }
    },
    orderSpanMethod ({ row, column, rowIndex, columnIndex }) {
      return helper.spanRow({ row, column, rowIndex, columnIndex }, this.products, this.option)
    },
    generateData (item, warehouse, selectedWarehouse) {
      var returnData = '-'
      warehouse.forEach((element) => {
        if (element.warehouse_id === selectedWarehouse) {
          if (item === 'rack') {
            returnData = element.rack !== undefined ? element.rack : '-'
          } else if (item === 'shelf') {
            returnData = element.shelf !== undefined ? element.rack : '-'
          } else if (item === 'unit') {
            returnData = element.unit !== undefined ? element.rack : '-'
          }
        }
      })
      return returnData
    },

    handleSelectionChange (val) {
      this.selectedData = val
    },

    getOrders (from, size, search, load) {
      if (load) {
        this.loading = true
      }
      const query = '?search_text=' + search + '&from=' + from + '&size=' + size
      services.getOrdersDetails(query).then(response => {
        if (response.status === 'success') {
          response.orderItems.forEach((element, elementIndex) => {
            if (element.warehouses.length > 0) {
              response.orderItems[elementIndex].selectedWarehouse = element.warehouses[0].warehouse_id
            }
          })
          this.products = response.orderItems
          this.totalCount = response.total
        }
        this.loading = false
      }).catch(() => {
        this.loading = false
      })
    },

    setFullFillment (productId) {
      this.loading = true
      services.setFullfillment(productId).then(response => {
        this.alertReporter(response.status === 'success' ? 'Амжилттай' : 'Уучлаарай', response.message, response.status)
        if (response.status === 'success') {
          this.getOrders(false)
        } else {
          this.loading = false
        }
      }).catch(() => {
        this.alertReporter('Уучлаарай', 'Алдаа гарлаа', 'error')
        this.loading = false
      })
    },
    alertReporter (title, message, type) {
      this.$notify({
        title: title,
        message: message,
        type: type,
        position: 'bottom-left'
      })
    },
    tabClick () {
      this.getOrders(true)
    }
  }
}
</script>
<style>
  .el-table .warning-row {
    background: oldlace;
  }

  .el-table .success-row {
    background: #f0f9eb;
  }
</style>
